:root {
  --darkbgcard: #2a2a2a;
}

@import url("https://fonts.googleapis.com/css?family=Raleway");
* {
  box-sizing: border-box;
}
h1.title {
  position: absolute;
    top: 8%;
    left: 18%;
    font-size: 3em;
}
div.linkscontainer {
  display: flex;
}
.button {
  position: relative;
  padding: 1em 1.5em;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  font-size: 18px;
  margin: 1em 0.8em;
  text-decoration: none;
}
.button.fancy {
  color: #333;
}
.button.fancy.fancy::after, 
.button.fancy.fancy::before {
  content: "";
  display: block;
  position: absolute;
  width: 20%;
  height: 20%;
  border: 2px solid;
  transition: all 0.3s ease;
  border-radius: 2px;
}
.button.fancy.fancy::after {
  bottom: 0;
  right: 0;
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom-color: #333;
  border-right-color: #333;
}
.button.fancy.fancy::before {
  top: 0;
  left: 0;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-top-color: #333;
  border-left-color: #333;
}
.button.fancy.fancy:hover:after, .button.fancy.fancy:hover:before {
  border-bottom-color: #333;
  border-right-color: #333;
  border-top-color: #333;
  border-left-color: #333;
  width: 100%;
  height: 100%;
}
/* Projects Page */
#projects {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: fixed;
  overflow: scroll
}
#projects > div {
  width: 80vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.entry-container{
  position: absolute;
    left: 10vw;
    width: 80vw;
    top: 28%;
}
.entry-container > div {
  display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.entry{
  width: 300px;
  height: 350px;
  border: 1px solid #333;
  border-radius: 5px;
  padding: 2%;
  overflow-y: hidden;
  margin: 1%;
  cursor: pointer;
  background-color: #fff;
  text-decoration: none;
  transition: transform .2s;
}
.entry:hover{
  transform: scale(1.03);
}
.entry img{
  width: 100%;
  border-radius: 5px;
}
.entry .entry-title{
  font-size: 1.2em;
  color: #000;
  font-weight: bold;
}
.entry .description {
  color: #000;
}
.filter-options{
  position: absolute;
  top: 22%;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.filter-options button {
  background-color: #fff;
  color: #000;
  padding: 0.6em 1em;
  margin: 0% 0.5em;
  border: 1px solid #000;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.1s;
}
.filter-options button:hover {
  color: #fff;
  background-color: #000;
}
.dark #projects * {
  color: #fff;
}
.dark .entry {
  background-color: var(--darkbgcard);
}
.dark .entry:hover {
  border: 1px solid #fff;
}
.dark #projects div.filter-options button {
  background-color: var(--darkbgcard);
}
/* Contact Page */
#contact {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: fixed;
}
#contact > div {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#contact > div.hero {
  max-width: 400px;
}
#contact > div.hero > div.hero_container {
  -webkit-box-shadow: 6px 9px 9px -7px rgba(0,0,0,0.4);
  -moz-box-shadow: 6px 9px 9px -7px rgba(0,0,0,0.4);
  box-shadow: 6px 9px 9px -7px rgba(0,0,0,0.4);
}
#contact > div.hero img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
  height: 100%;
}
#contact .form-container {
  padding: 0em 3em;
}
#contact-form{
  display: flex;
  flex-direction: column;
}
#contact-form input,
#contact-form > textarea{
  width: 100%;
  margin: 0.1em 0em 1em 0em;
  border-radius: 3px;
  border: 1px solid #333;
  padding: 0.5em;
  font-size: 1em;
  font-family: inherit;
}
#contact-form > textarea{
  height: 100px;
  resize: none;
}
#contact-form > textarea:focus-visible{
  border-color: #333;
  outline: none;
}
#contact-form > button {
  cursor: pointer;
  font-size: 1em;
  background-color: #fff;
  border: 1px solid #333;
  border-radius: 3px;
  padding: 0.5em;
  transition: background-color .2s
}
#contact-form > button:hover {
  background-color: #333;
  color: #fff;
}
#contact .gohome {
  background-color: #fff;
  color: #000;
  padding: 0.6em 1em;
  border: 1px solid #333;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.1s;
  width: 180px;
  display: flex;
  justify-content: space-around;
}
#contact .gohome:hover {
  color: #fff;
  background-color: #333;
}
.dark #contact .title,
.dark #contact .form-container {
  color: #fff;
}
/* Media Queries */
@media (min-width: 830px) {
  .topnav {
      display: none;
  }
}
@media (max-width: 830px) {
  aside {
      flex-direction: row;
      position: fixed;
      bottom: 0;
      width: 100%;
      height: 10%;
      justify-content: center;
      transform: unset !important;
  }
  aside #darkmode_slider_container {
      position: fixed;
      left: 5%;
      bottom: 3%;
  }
  /* Home */
  #about .hero {
      display: none;
  }
  #about > div {
      width: 80%;
  }
  #about .description {
      padding-right: 0em;
  }
  #about .headline {
      text-align: center;
  }
  nav ul,
  #navbar_contact_container {
      display: none;
  }
  .topnav {
      display: block;
  }
  .topnav a{
      padding: 3% 6%;
      font-size: 1.5em;
  }
  .topnav #myLinks {
      width: 40vw;
  }
  /* Contact */
  #contact > div {
    width: 45%
  }
}
@media (max-width: 600px) {
  /* Contact */
  #contact {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #contact h1.title {
    position: unset;
  }
  #contact > div {
    width: 80%;
  }
  #contact > div.hero {
    display: none;
  }
}
@media (max-width: 480px) {
  aside #darkmode_slider_container {
      position: unset;
      margin-bottom: 6%;
  }
  div.fullname {
      font-size: 3em !important;
  }
  div.description {
      font-size: 0.8em !important;
  }
  div.linkscontainer {
      justify-content: center;
  }
  .topnav a{
    padding: 5% 6%;
  }
  /* Contact */
  #contact > div {
    width: 100%;
  }
}